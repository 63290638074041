import React from "react";
import { Link, useLocation } from "react-router-dom";
import DOMPurify from 'dompurify';

const ControlPanelNewsDetails = () => {

    const pathLocation = useLocation();

    const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
    }

    return(
        <div>
            <h1 className="title">Manage News - Details</h1>
            
            <hr></hr>
            <div className="contentForm">               
                <Link to="/ControlPanel/News">
                    <button className="custom-button">News</button>
                </Link>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <h2 className="title">{pathLocation.state.name}</h2>
                <br/>
                
                <div className="preview" dangerouslySetInnerHTML={createMarkup(pathLocation.state.description)}></div>
            </div>
        </div>    
    )
}

export default ControlPanelNewsDetails;