import React, { useEffect, useState } from "react";
import { NoticeService } from "./service/NoticeService";
import dateFormat from "dateformat";
import Pagination from 'react-bootstrap/Pagination'
import GetDateLocale from "../../../services/GetDateLocale";
import { Link } from "react-router-dom";

export const Notice = () => {

    const {
        handleNotices,
        handlePage,
        handleFixedNotice,
        notices,
        page,
        totalPages,
        fixedNotice
    } = NoticeService();

    const [noticeType, setNoticeType] = useState(0);
    async function GetNoticeByCategory(type){
        setNoticeType(type);
    }
  
    useEffect(() => {
        handleNotices(1, noticeType);
        handleFixedNotice();
        document.body.className = "news";
    },[]);

    useEffect(() => {
        handleNotices(1, noticeType);
    },[noticeType]);

    useEffect(() => {
        handleNotices(page, noticeType);
    },[page]);

    

    let items = [];
    items.push(<Pagination.Item key={'left'} onClick={handlePage(1)}><span aria-hidden="true">«</span></Pagination.Item>);
    items.push(<Pagination.Item key={'left1'} onClick={handlePage(page - 1)}><span aria-hidden="true">‹</span></Pagination.Item>);
    items.push(
        <Pagination.Item activeLabel={false} key={page} onClick={handlePage(1)} active={true}>
        {page}
        </Pagination.Item>
    );
    if( page !== totalPages && totalPages > 0){
        items.push(
            <Pagination.Item activeLabel={false} key={'total1'} onClick={handlePage(totalPages)} active={false}>
            {totalPages}
            </Pagination.Item>
        );
    }
    items.push(<Pagination.Item key={'rigth'} onClick={handlePage(page + 1)}><span aria-hidden="true">›</span></Pagination.Item>);
    items.push(<Pagination.Item key={'rigth1'} onClick={handlePage(totalPages)}><span aria-hidden="true">»</span></Pagination.Item>);

    return(
        <div>
            
            <section className="news">
                <div className="container lg-container">
                    <div className="news-top-banner mb-2">
                    {fixedNotice && 
                        <div className="news-card">
                        <picture>
                            <img
                            src={fixedNotice.imageIcon}
                            alt="news_cover"
                            className="img-fluid"
                            />
                        </picture>
                        <div className="news-content">
                            <div className="info">
                            {(() => {
                                if (fixedNotice.type == 1) {
                                    return (
                                        <span className="category">News</span>
                                    )
                                } else if (fixedNotice.type == 2) {
                                    return (
                                        <span className="category">Events</span>
                                    )
                                } else if (fixedNotice.type == 3) {
                                        return (
                                            <span className="category">Update</span>
                                        )
                                    } 
                            })()}
                            <span className="date">{dateFormat(fixedNotice.createdAt, GetDateLocale())}</span>
                            </div>
                            <h1 className="title">{fixedNotice.name}</h1>
                            <div className="text">
                            <Link to={{pathname:"/Notice/Read",
                            state : {
                                notice : fixedNotice
                            }
                            }}>
                                Continue on to find out more...
                            </Link>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </section>
        <section className="small-news">
            <div className="container">
            <div className="news-types">
                <Link to="#" onClick={() => GetNoticeByCategory(0)} className={noticeType == 0 ? 'switch-tab active' : 'switch-tab'}>
                All
                </Link>
                <Link to="#" onClick={() => GetNoticeByCategory(1)} className={noticeType == 1 ? 'switch-tab active' : 'switch-tab'}>
                News
                </Link>
                <Link to="#" onClick={() => GetNoticeByCategory(2)} className={noticeType == 2 ? 'switch-tab active' : 'switch-tab'}>
                Events
                </Link>
                <Link to="#" onClick={() => GetNoticeByCategory(3)} className={noticeType == 3 ? 'switch-tab active' : 'switch-tab'}>
                Updates
                </Link>
            </div>
            <div className="news-body">
            {notices.Items.map((notice, key) => (
                <div key={key} className="tab active" id="all">
                    <div className="news-post">
                        <picture>
                        <img
                            src={notice.imageIcon}
                            alt="news_image"
                            className="img-fluid"
                        />
                        </picture>
                        <div className="news-cont">
                        {(() => {
                        if (notice.type == 1) {
                            return (
                                <Link to={{pathname:"/Notice/Read",
                                state : {
                                    notice : notice
                                }
                                }}>
                                    <h1 className="text-success">News - <span>{notice.name}</span></h1>
                                </Link>
                            )
                        } else if (notice.type == 2) {
                            return (
                                <Link to={{pathname:"/Notice/Read",
                                state : {
                                    notice : notice
                                }
                                }}>
                                    <h1 className="text-info">Events - <span>{notice.name}</span></h1>
                                </Link>
                            )
                        } else if (notice.type == 3) {
                                return (
                                    <Link to={{pathname:"/Notice/Read",
                                    state : {
                                        notice : notice
                                    }
                                    }}>
                                        <h1 className="text-danger">Update - {notice.name}</h1>
                                    </Link>
                                )
                            } 
                        })()}
                        <p>{dateFormat(notice.createdAt, "dd/mm/yyyy")}</p>
                        </div>
                    </div>
                </div>
            ))}
            </div>
            <div className="pagination mt-3 p-3">
                <nav>
                <Pagination size="sm">{items}</Pagination>
                </nav>
            </div>
            </div>
        </section>
        </div>
    )
}