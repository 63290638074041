import React, {useEffect} from "react";
import ControlPanelIndicationService from "./service/ControlPanelIndicationService";
import dateFormat from "dateformat";
import Pagination from 'react-bootstrap/Pagination'
import GetDateLocale from "../../../services/GetDateLocale";
import { Link } from "react-router-dom";
import Moment from 'react-moment';

export const ControlPanelIndicationCash = () => {

    const {
        handleTransactions,
        handlePage,
        handleConvertTransaction,
        transactions,
        page,
        totalPages
    } = ControlPanelIndicationService()

    useEffect(() => {
        handleTransactions(page);
    },[]);

    useEffect(() => {
        handleTransactions(page);
    },[page]);

    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    let items = [];
    items.push(<Pagination.Item key={'left'} onClick={handlePage(1)}><span aria-hidden="true">«</span></Pagination.Item>);
    items.push(<Pagination.Item key={'left1'} onClick={handlePage(page - 1)}><span aria-hidden="true">‹</span></Pagination.Item>);
    items.push(
        <Pagination.Item activeLabel={false} key={page} onClick={handlePage(1)} active={true}>
        {page}
        </Pagination.Item>
    );
    if( page !== totalPages && totalPages > 0){
        items.push(
            <Pagination.Item activeLabel={false} key={'total1'} onClick={handlePage(totalPages)} active={true}>
            {totalPages}
            </Pagination.Item>
        );
    }
    items.push(<Pagination.Item key={'rigth'} onClick={handlePage(page + 1)}><span aria-hidden="true">›</span></Pagination.Item>);
    items.push(<Pagination.Item key={'rigth1'} onClick={handlePage(totalPages)}><span aria-hidden="true">»</span></Pagination.Item>);

    return(
        <div>
            <h1 className="title">Result - Donations from your referrals</h1>
            <hr></hr>
            <div className="contentForm">
            <div className="table-responsive">
                <table className="table-system">
                    <thead>
                    <tr>
                        <th>Value</th>
                        <th>Date</th>
                        <th>Donated</th>
                        <th>Convert</th>
                    </tr>
                    </thead>
                    <tbody>
                    {transactions.Items.map((item, key) => (
                    <tr key={key}>
                        <td>{item.cashCount}</td>
                        <td>{dateFormat(item.createdAt, GetDateLocale())}</td>
                        {(() => {
                            var date1 = new Date(item.createdAt)
                            var date2 = new Date(item.createdAt).addDays(20)

                            if (item.receivedAt == null && date1 >= date2) {
                            return (
                                <td className="text-success"><Link to="#" onClick={(e) => handleConvertTransaction(e, item.Id, 2)}>Sacar</Link></td>
                            )
                            } else if (item.receivedAt == null && item.createdAt < (item.createdAt + 20)) {
                            return (
                                <td className="text-info">wait until <Moment add={{days : 20}} format="DD/MM/YYYY">{item.createdAt}</Moment></td>
                            )
                            } else if (item.receivedAt != null) {
                            return (
                                <td className="text-success">Received</td>
                            )
                        } 
                        })()}
                        {(() => {
                            if (item.receivedAt == null) {
                            return (
                                <td className="text-info"><Link to="#" onClick={(e) => handleConvertTransaction(e, item.Id, 1)}>Converter</Link></td>
                            )
                            } else if (item.receivedAt != null) {
                            return (
                                <td className="text-success">Received</td>
                            )
                        } 
                        })()}
                    </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            </div>
            <br/>
            <Pagination size="sm">{items}</Pagination>
        </div>
    )
}