import React, {useState} from "react";
import Swal from "sweetalert2";
import Api from "../../../../../services/Api";

export const RecoverAccountService = (callback, validate) => {

    const [errors, setErrors] = useState([]);

    const [values, setValues] = useState({
        Username: "",
        Code : "",
        Password : "",
        ConfirmPassword : "",
        isVerified : false
    });

    const [returnData, setReturnData] = useState({
        accountId : 0,
        expireSeconds : 0
    });

    const [isVerified, setChecked] = useState(false);

    const handleCaptchaChange = e => {
        if(e){
            setChecked(true);
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });
    };

    const handleSubmitRecoverCode = async e => {
        e.preventDefault();

        const form_data = new FormData();
        form_data.append('Username', values.Username);
        
        setErrors("");

        if(isVerified == false){
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Information',
            text : 'Recaptcha invalid!',
            showConfirmButton: false,
            timer: 16500,
            background: 'rgba(43, 43, 46, 0.8)'
          })
          return;
        }

        await Api.post("/Auth/SendRecoverCode", form_data)
        .then((response) =>{
          setReturnData(response.data);
        })
        .catch((err) => {
          if(err.response){
            var errors = err.response.data.errors;
            setErrors(validate(errors));
          }
        });
    };

    const handleSubmitChangePasswordRecoverCode = async e => {
        e.preventDefault();
  
        const form_data = new FormData();
        form_data.append('accountId', returnData.accountId)
        form_data.append('Code', values.Code);
        form_data.append('Password', values.Password);
        form_data.append('ConfirmPassword', values.ConfirmPassword);
        
        setErrors("");
  
        await Api.post("/Auth/ChangePasswordRecoverCode", form_data)
        .then((response) =>{
            setValues(
                {
                    Username: "",
                    Code : "",
                    Password : "",
                    ConfirmPassword : "",
                    isVerified : false
                }
            )
        })
        .catch((err) => {
          if(err.response){
            var errors = err.response.data.errors;
            setErrors(validate(errors));
          }
        });
    };

    return{
        errors,
        values,
        isVerified,
        returnData,
        handleCaptchaChange,
        handleChange,
        handleSubmitRecoverCode,
        handleSubmitChangePasswordRecoverCode
    }

}