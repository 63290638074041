import React, {useState} from "react";
import Api from "../../../../services/Api";
import { secondsToTime } from "../../../../services/Functions";
import Swal from "sweetalert2";
import { getAccount, updateAccountData } from "../../../../services/Auth";

const ControlPanelAccountService = (callback, validate) => {

    const [errors, setErrors] = useState([]);

    const [values, setValues] = useState({
        accountIndication: ""
    });

    const [accounts, setAccounts] = useState([])

    const handleChange = async e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });

        await Api.get("/Account/FindLikeAccountsByUsername/" + values.accountIndication)
        .then((response) =>{
          setAccounts(response.data)
        })
        .catch((err) => {

        });
    };

    const handleSubmitSaveIndication = async e => {
      e.preventDefault()
      
      const form_data = new FormData();

      form_data.append('accountIndication', values.accountIndication);

      setErrors("");

      await Api.post("/Account/SaveIndicationAccount", form_data)
      .then((response) =>{
      })
      .catch((err) => {
        if(err.response){
          var errors = err.response.data.errors;
          setErrors(validate(errors));
        }
      });

      updateAccountData();
    }

    const handleAccountActivate = async e => {
        e.preventDefault();

        await Api.get("/Account/SendActivationCode")
        .then((response) =>{
  
          let timerInterval;
  
          setTimeout(() => {  }, 2000);
  
          Swal.fire({
            background: 'rgba(25, 20, 20)',
            title: 'Activate your account',
            html: 'You have <b></b> minutes to activate your account.<p>Please check your junk-email folder !',
            timer: (response.data.expireSeconds * 1000),
            timerProgressBar: true,
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off',
            },
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
            inputPlaceholder: 'Enter the activation code received by email',
            didOpen: () => {
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                var timePrint = secondsToTime(Swal.getTimerLeft() / 1000);
                b.textContent = timePrint.h + ":" + timePrint.m + ":" + timePrint.s;
              }, (response.data.expireSeconds / 1000))
            },
            preConfirm: (ActivationCode) => {
              const form_data = new FormData();
              form_data.append('ActivationCode', ActivationCode);
              return Api.post("/Account/ActivateAccount", form_data)
              .then(response =>{
                return response.json();
              }).catch(error => {
                if(error.response){
                  Swal.showValidationMessage(
                    error.response.data.errors.ActivationCode[0]
                  )
                }
              })
            },
            willClose: () => {
              clearInterval(timerInterval)
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'PrimeBD',
                text : 'Account activated successfully!',
                showConfirmButton: false,
                timer: 6500,
                background: 'rgba(43, 43, 46, 0.8)'
              })
              updateAccountData();
            }
          })
        })
        .catch((err) => {
          
        });

      }

     return { 
       handleAccountActivate,
       handleChange,
       handleSubmitSaveIndication,
       errors,
       values,
       accounts
      };
    
}

export default ControlPanelAccountService;