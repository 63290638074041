import React from "react";

const Footer = () => {
    return(
    <div>
        <footer>
            <div className="container xl-container">
                <div className="social-icons">
                    <a href="#">
                        <i className="fa fa-facebook" />
                    </a>
                    <a href="#">
                        <i className="fa fa-twitter" />
                    </a>
                    <a href="#">
                        <i className="fa fa-youtube" />
                    </a>
                    <a href="#">
                    <i className="fa fa-instagram" />
                        </a>
                    <a href="#">
                        <i className="fa fa-twitch" />
                    </a>
                </div>
                <div className="row">
                    <div className="col-md-6 text-left">
                        <ul>
                        <li>
                            <a href="#">about us</a>
                        </li>
                        <li>
                            <a href="#">Terms of use</a>
                        </li>
                        <li>
                            <a href="#">Privacy Policy</a>
                        </li>
                        </ul>
                    </div>
                    <div className="col-md-6 text-right">
                        <img src="/assets/images/logo.png" className="img-fluid" alt="logo" />
                    </div>
                </div>
                <div className="copyright">
                    <p>
                        Copyright 2022 © PrimeBD
                    </p>
                </div>
                    </div>
    </footer>
    </div>
    )
}

export default Footer;