import React from "react";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import CashShopService from "./service/CashShopService";
import DOMPurify from 'dompurify';

export const CashShopItemDetails = () => {

    let history = useHistory();
    let location = useLocation()

    const {
        addItemToCart
    } = CashShopService()

    const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
    }
    
    return(
        <div className="itenBlocks flex">
            <div className="detalhesFlex flex">
            {location.state ? 
            <>
            <div className="detalhesFlex-left">
                <span className="corner corner-purple">
                <span>N</span>
                </span>
                <div className="detalhes-img flex-c-c">
                <img src={"/assets/images/item/" + location.state.itemId + ".png"} />
                </div>
                <div className="detalhes-title">{location.state.name}</div>
                <div className="detalhes-price">
                <i className="icon icon-ic" /> {location.state.price * location.state.count}
                </div>
                <div className="detalhes-button">
                <Link to="#" onClick={(e) => addItemToCart(e, location.state)} className="button">
                    <i className="icon icon-market-white" /> PURCHASE
                </Link>
                <Link to='#' onClick={() => history.goBack()} className="button" style={{position:'relative', bottom:'-5px'}}>
                    BACK
                </Link>
                </div>
            </div>
            <div className="detalhesFlex-right">
                <div className="detalhes-blocks">
                <span className="h3">
                    <i/> Item Description
                </span>
                </div>
                <div className="detalhes-blocks">
                    <div className="detalhes-block-flex">
                        <div className="detalhes-block" dangerouslySetInnerHTML={createMarkup(location.state.description)}></div>
                        <div className="detalhes-block">Please read carefully before purchasing.</div>
                    </div>
                </div>
            </div>
            </>
            :
            <></>
            }
        </div>
        </div>
        
    )
}