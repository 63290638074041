import React,{useState} from "react";
import Api from "../../../../services/Api";
import { updateAccountData } from "../../../../services/Auth";
import Swal from "sweetalert2";

const ControlPanelAccountChangePassService = (callback, validate) => {

    const [errors, setErrors] = useState([]);

    const [values, setValues] = useState({
        currentPassword: "",
        newPassword : "",
        confirmNewPassword : "",
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });
    };

    const handleSubmitChangePass = async e => {
        e.preventDefault();

        const form_data = new FormData();
        form_data.append('currentPassword', values.currentPassword);
        form_data.append('newPassword', values.newPassword);
        form_data.append('confirmNewPassword', values.confirmNewPassword);
        
        setErrors("");

        await Api.post("/Account/ChangePassword", form_data)
        .then((response) =>{
            updateAccountData();
            setValues({
                currentPassword : "",
                newPassword : "",
                confirmNewPassword: ""
            });
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Information',
                text : 'Password changed successfully!',
                showConfirmButton: false,
                timer: 16500,
                background: 'rgba(43, 43, 46, 0.8)'
            })
        })
        .catch((err) => {
          if(err.response){
            var errors = err.response.data.errors;
            setErrors(validate(errors));
          }
        });
    };

    return {
        errors,
        values,
        handleChange,
        handleSubmitChangePass
    }
}

export default ControlPanelAccountChangePassService;