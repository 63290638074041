import Api from "./Api";

export const TOKEN_KEY = "@airbnb-Token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const account = data => {
  localStorage.setItem("account", JSON.stringify(data));
};
export const getAccount = () => {
  const data = JSON.parse(localStorage.getItem("account"));
  return data;
};
export const user = data => {
  localStorage.setItem("user", JSON.stringify(data));
}
export const getUser = () => {
  const data = JSON.parse(localStorage.getItem("user"))
  return data
}
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const updateAccountData = async () => {
    await Api.get("/Auth/GetAccountData")
    .then((response) => {
      account(response.data)
      setTimeout(() => { 
        window.location.reload()
      }, 6000);
      //
    })
    .catch((err) => {

    })
}