import React,{ useState } from "react";
import Api from "../../../../../../services/Api";
import { lastSearch, getSearch } from "../../../../../../services/SearchData";

export const ControlPanelUpdateCashAccountService = (callback, validate) => {
    
    const [errors, setErrors] = useState({});

    const [values, setValues] = useState({
        Search: "",
        Cash : ""
    });

    const [accountData, setAccountData] = useState()
  
    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
        ...values,
        [name]: value
        });
    };

    const handleSubmitFindAccount = async e => {
        e.preventDefault();

        const form_data = new FormData();
        form_data.append('Search', values.Search);
        
        setErrors("");
        setAccountData()
        
        await Api.post("/Account/FindAccount", form_data)
        .then((response) =>{
            setAccountData(response.data)
            lastSearch(values.Search, response.data)
        })
        .catch((err) => {
            if(err.response){
            var errors = err.response.data.errors;
            setErrors(validate(errors));
            }
        });
    }

    const handleUpdateAccountCash = accountId => async e => {
        e.preventDefault()

        const form_data = new FormData();
        form_data.append('accounId', accountId);
        form_data.append('Cash', values.Cash);
        
        setErrors("");
        
        await Api.post("/Account/UpdateAccount", form_data)
        .then((response) =>{
            lastSearch(values.Search, response.data.account)
            setInterval(() => {
               window.location.reload()
            }, 3000);
        })
        .catch((err) => {
            if(err.response){
            var errors = err.response.data.errors;
            setErrors(validate(errors));
            }
        });
    }

    return {
        handleChange,
        handleSubmitFindAccount,
        handleUpdateAccountCash,
        errors,
        values,
        accountData
    }

}