import React from 'react';
import Modal from 'react-bootstrap/Modal'
import ReCAPTCHA from "react-google-recaptcha";
import ValidateForm from '../../../../services/ValidateForm';
import SigninService from './service/SigninService';
import { RECAPTCHA_KEY } from '../../../../Configuration';

const Signin = (props, {submitForm}) => {

    const { handleChange, handleSubmitLogin, handleCaptchaChange,
        values, errors
    } = SigninService(
      submitForm,
      ValidateForm
    );

    return(
      <div>
          <Modal
              show={props.isOpen}
              onHide={props.toggle}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
            >
            <form onSubmit={handleSubmitLogin}>
            <div className="modal-header">
              <h5 className="modal-title" id="loginLabel">
                  Login
              </h5>
              <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  >
              <span aria-hidden="true" onClick={props.toggle}>×</span>
              </button>
            </div>
              <Modal.Body>
                  <div className={values.Username === "" ? "formGroup" : "formGroup on"}>
                      <label>Username</label>
                      <input type="text" name="Username"
                        value={values.Username}
                        onChange={handleChange}
                      />
                  </div>
                  {errors.Username && 
                    <div className="form-msg form-error">
                        <i className="sp-form noti"></i> {errors.Username}
                    </div>
                  }
                  <div className={values.Password === "" ? "formGroup" : "formGroup on"}>
                      <label>Password</label>
                      <input type="password" name="Password"
                          value={values.Password}
                          onChange={handleChange}
                      />
                  </div>
                  {errors.Password && 
                    <div className="form-msg form-error">
                        <i className="sp-form noti"></i> {errors.Password}
                    </div>
                  }
                  <div className="formGroup">
                      <ReCAPTCHA
                            sitekey={RECAPTCHA_KEY}
                            onChange={handleCaptchaChange}
                        />
                  </div>
              
              </Modal.Body>
              <Modal.Footer>
                <div className="formRow">
                  <div className="formLinks">
                      <a href="/Register" className="signUp">
                        Register
                      </a>
                  </div>
                </div>
                <button className="custom-button">Login</button>
              </Modal.Footer>
            </form>
            </Modal>
      </div>
    )
}

export default Signin;