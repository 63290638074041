import React, { useEffect, useState } from "react";
import { ControlPanelVoteService } from "./service/ControlPanelVoteService";
import { getAccount } from "../../../services/Auth";
import { secondsToTime } from "../../../services/Functions";

export const ControlPanelVote = () => {

    const {
        handleVoteServers,
        handleVote,
        CheckVote,
        voteservers
    } = ControlPanelVoteService()

    useEffect(() => {
        handleVoteServers()
    },[])

    useEffect(() => {
        setInterval(() => {
            handleVoteServers()
        }, 60000)
    })
    
    return(
        <div>
            <h1 className="title">Vote for us</h1>
            <hr></hr>
            <div className="contentForm">

                <hr></hr>
                <div className="row row-cols-md-3 mb-5">
                    {voteservers.map((result, item) => (
                        <div key={item} className="mb-5 text-center" style={{marginLeft:"10px"}}>
                        <div className="containerVote">
                            <img 
                             onMouseOver={e => (e.currentTarget.src = "/assets/images/" + result[1].imgHover)}
                             onMouseOut={e => (e.currentTarget.src = "/assets/images/" + result[1].img)}
                             src={"/assets/images/" + result[1].img} 
                             alt={result[1].voteServerName} 
                             onClick={() => CheckVote(
                                result[0], 
                                result[1].Id, 
                                result[1].clientUrl, 
                                result[1].voteServerParamFunction,
                                result[1].passUserInUrl,
                                    null, // if use arena top 100 change for result[1].voteParam 
                                result[1].baseUrl
                            )}
                             />
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    )
}