import React from "react";
import ControlPanelAccountChangePassService from "./service/ControlPanelAccountChangePassService";
import ValidateForm from "../../../services/ValidateForm";

const ControlPanelAccountChangePass = ({submitForm}) => {

    const {

        errors,
        values,
        handleChange,
        handleSubmitChangePass

    } = ControlPanelAccountChangePassService(
        submitForm,
        ValidateForm
    );

    return(
        <div>
            <h1 className="title">Change Password</h1>
            <div className="contentForm">
                <form onSubmit={handleSubmitChangePass}>
                    <div className={values.currentPassword === "" ? "formGroup" : "formGroup on"}>
                        <label>Current Password</label>
                        <input type="password" name="currentPassword"
                            value={values.currentPassword}
                            onChange={handleChange}
                        />
                    </div>
                    {errors.currentPassword && 
                    <div className="form-msg form-error">
                        <i className="sp-form noti"></i> {errors.currentPassword}
                    </div>
                    }

                    <div className={values.newPassword === "" ? "formGroup" : "formGroup on"}>
                        <label>New password</label>
                        <input type="password" name="newPassword"
                            value={values.newPassword}
                            onChange={handleChange}
                        />
                    </div>
                    {errors.newPassword && 
                    <div className="form-msg form-error">
                        <i className="sp-form noti"></i> {errors.newPassword}
                    </div>
                    }

                    <div className={values.confirmNewPassword === "" ? "formGroup" : "formGroup on"}>
                        <label>Confirm New Password</label>
                        <input type="password" name="confirmNewPassword"
                            value={values.confirmNewPassword}
                            onChange={handleChange}
                        />
                    </div>
                    {errors.confirmNewPassword && 
                    <div className="form-msg form-error">
                        <i className="sp-form noti"></i> {errors.confirmNewPassword}
                    </div>
                    }
                    <button className="custom-button">CHANGE</button>
                </form>
            </div>
        </div>
    )
}

export default ControlPanelAccountChangePass;