import React,{useState} from "react";
import Api from "../../../../services/Api";
import Swal from "sweetalert2";
import { setCart, getCart } from "../../../../services/CashShopCart";

const CashShopService = (callback, validate) => {

    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        Name : ""
    });
    const [categories, setCategories] = useState([]);
    
    const [itemList, setitemList] = useState({
        Items : []
    });
    const [shopCartCount, setShopCartCount] = useState(0)

    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);

    const[firstCat, setFirstCat] = useState(0)

    const handlePage = pageId => async e => {

        if(pageId < 1)
            return;

        if(pageId > totalPages)
            return;

        setPage(pageId);
    }
       
    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
        ...values,
        [name]: value
        });
    };

    const handleCashShopCategories = async e => {
        await Api.get("/Shop/GetCategories")
        .then((response) =>{
            setCategories(response.data);
        })
        .catch((err) => {

        });
    }

    const handleCashShopItems = async (page, category, subcategory) => {
        var pageItems = 8

        await Api.get("/Shop/GetItems/" + page + "/" + pageItems + "/" + category + "/" + subcategory)
        .then((response) =>{
            setitemList({
                Items : response.data.Items
            })
            setTotalPages(response.data.PageCount)
        })
        .catch((err) => {

        });
    }

    const handleSearch = async () => {
        setErrors("");

        await Api.get("/Shop/FindItem/" + values.Name)
        .then((response) =>{
            setitemList({
                Items : response.data.Items
            })
            setTotalPages(response.data.PageCount)
        })
        .catch((err) => {
            if(err.response){
            var errors = err.response.data.errors;
                setErrors(validate(errors));
            }
        });
    }

    const addItemToCart = (e, item) => {
        e.preventDefault()

        if(getCart() != null){
            setCart(getCart() + ',' + item.Id)
        }else{
            setCart(item.Id)
        }
        
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Sucessfull',
            text : 'Item added to shopping cart!',
            showConfirmButton: false,
            timer: 6500,
            background: 'rgba(43, 43, 46, 0.8)'
        })

        handleShopCartItemCount()
        setTimeout(() => {
            window.location.reload()
        }, 3000);
    }

    const handleShopCartItemCount = () => {

       if(getCart() == null){
            setShopCartCount(0)
            return;
       }

       if(!isNaN(Number(getCart()))){
            setShopCartCount(1)
       }else{
            const items = getCart().split(',');
            setShopCartCount(items.length)
       }
    }

    const handleFirstPage = async () => {
        setErrors("");

        await Api.get("/Shop/GetFirstPage")
        .then((response) =>{
            setFirstCat(response.data)
        })
        .catch((err) => {
        });
    }


    return {
        handleChange,
        handleCashShopCategories,
        handleCashShopItems,
        handleSearch,
        addItemToCart,
        handleShopCartItemCount,
        handlePage,
        handleFirstPage,
        categories,
        itemList,
        errors,
        values,
        shopCartCount,
        page,
        totalPages,
        firstCat
    }
}

export default CashShopService;