import React, {useState} from "react";
import Api from "../../../../services/Api";

const ControlPanelTransactionsService = () => {

    const [transactions, setTransactions ] = useState(
        {
            Items : []
        }
    );
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handlePage = pageId => async e => {

        if(pageId < 1)
            return;

        if(pageId > totalPages)
            return;

        setPage(pageId);
    }
  
    const handleTransactions = async pageId => {
        await Api.get("/Donate/GetTransactions/" + pageId)
        .then((response) =>{
            setTransactions(response.data.transactions);
            setTotalPages(response.data.transactions.PageCount);
           })
            .catch((err) => {
        });
    }
    
    return { 
            handleTransactions,
            handlePage,
            transactions,
            page,
            totalPages
        };
}

export default ControlPanelTransactionsService;