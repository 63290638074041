import React, {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal'
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_KEY } from "../../../../Configuration";
import ValidateForm from "../../../../services/ValidateForm";
import { RecoverAccountService } from "./service/RecoverAccountService";
import {secondsToTime} from "../../../../services/Functions";

export const RecoverAccount = (props, {submitForm}) => {

    const {

      errors,
      values,
      isVerified,
      returnData,
      handleCaptchaChange,
      handleChange,
      handleSubmitRecoverCode,
      handleSubmitChangePasswordRecoverCode

    } = RecoverAccountService(
      submitForm,
      ValidateForm
    );

    const [counter, setCounter] = useState(returnData.expireSeconds);
    const [time, setTime] = useState();

    useEffect(() => {
        setCounter(returnData.expireSeconds);
    },[returnData.expireSeconds])

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        setTime(secondsToTime(counter));
    }, [counter]);

    // useEffect(() => {
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 6000);  
    // },[isChanged])

    return(
        <div>
        <Modal
            show={props.isOpen}
            onHide={props.toggle}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
          {counter <= 0 ?
          <form onSubmit={handleSubmitRecoverCode}>
          <div className="modal-header">
            <h5 className="modal-title" id="loginLabel">
              Account Recovery
            </h5>
            <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                >
            <span aria-hidden="true" onClick={props.toggle}>×</span>
            </button>
          </div>
            <Modal.Body>
                    <div>
                        <div className={values.Username === "" ? "formGroup" : "formGroup on"}>
                        <label>Username</label>
                        <input type="text" name="Username"
                          value={values.Username}
                          onChange={handleChange}
                        />
                        </div>
                        {errors.Username && 
                        <div className="form-msg form-error">
                          <i className="sp-form noti"></i> {errors.Username}
                        </div>
                        }
                        <div className="formGroup">
                          <ReCAPTCHA
                                sitekey={RECAPTCHA_KEY}
                                onChange={handleCaptchaChange}
                            />
                        </div>
                    </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="custom-button">Submit</button>
            </Modal.Footer>
          </form>
          
          :

          <form onSubmit={handleSubmitChangePasswordRecoverCode}>
          <div className="modal-header">
            <h5 className="modal-title" id="loginLabel">
              Account Recovery
            </h5>
            <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                >
            <span aria-hidden="true" onClick={props.toggle}>×</span>
            </button>
          </div>
            <Modal.Body>
                    <div>
                      <div className="formGroup">
                        <span className="text-danger">{time.h + ":" + time.m + ":" + time.s }</span>
                      </div>

                        <div className={values.Code === "" ? "formGroup" : "formGroup on"}>
                        <label>Code</label>
                        <input type="text" name="Code"
                          value={values.Code}
                          onChange={handleChange}
                        />
                        </div>
                        {errors.Code && 
                        <div className="form-msg form-error">
                          <i className="sp-form noti"></i> {errors.Code}
                        </div>
                        }

                      <div className={values.Password === "" ? "formGroup" : "formGroup on"}>
                        <label>Password</label>
                        <input type="password" name="Password"
                          value={values.Password}
                          onChange={handleChange}
                        />
                        </div>
                        {errors.Password && 
                        <div className="form-msg form-error">
                          <i className="sp-form noti"></i> {errors.Password}
                        </div>
                        }

                        <div className={values.ConfirmPassword === "" ? "formGroup" : "formGroup on"}>
                        <label>Confirm Password</label>
                        <input type="password" name="ConfirmPassword"
                          value={values.ConfirmPassword}
                          onChange={handleChange}
                        />
                        </div>
                        {errors.ConfirmPassword && 
                        <div className="form-msg form-error">
                          <i className="sp-form noti"></i> {errors.ConfirmPassword}
                        </div>
                        }

                     </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="custom-button">Change</button>
            </Modal.Footer>
          </form>         
                    
          } 
          </Modal>
    </div>   
    )
}