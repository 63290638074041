import React, { useEffect } from "react";
import ControlPanelDonateService from "./service/ControlPanelDonateService";
import ValidateForm from "../../../services/ValidateForm";
import GetCurrentMonetarySimbol from "../../../services/GetCurrentMonetarySimbol";

const ControlPanelDonate = ({submitForm}) => {

    const { 
        handleChange,
        handleCashPackets, 
        handleCashPacketFonts,
        handleBuy,
        values, 
        errors, 
        cashPackets, 
        cashPacketFonts 

    } = ControlPanelDonateService(
        submitForm,
        ValidateForm
    );

    useEffect(() =>{
        handleCashPacketFonts();
    },[])

    useEffect(() => {

        cashPacketFonts.forEach(element => {
            if(element.Id == values.cashPacketFontId)
                 return values.cashPacketMethod = element.methodName;   
        });

        handleCashPackets();


    }, [values.cashPacketFontId])

    return(
        <div>
            <h1 className="title">Donation</h1>
            <hr></hr>
            <div className="contentForm">

                If you want to donate, please visit this link and once you donated, please pm Enigma in Discord :).<br></br>
                <a href="https://boosty.to/primebd/donate" target="_blank">click me to donate</a>
            </div>
        </div>
    )
}

export default ControlPanelDonate;