import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DOMPurify from 'dompurify';
import dateFormat from "dateformat";
import GetDateLocale from "../../../services/GetDateLocale";

export const NoticeRead = () => {

    const pathLocation = useLocation();

    const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
    }
    
    useEffect(() => {
        document.body.className = "page";
    },[])
    
    return(
        <div>
            <div className="page-layout">
                <div className="breadcrumbs">
                <h1>{pathLocation.state.notice.name}</h1>
                <h5>{dateFormat(pathLocation.state.notice.createdAt, "dd/mm/yyyy")}</h5>
                </div>
            </div>
            <section className="downloads">
                <div className="container">
                <h2 className="dTitle">
                    {(() => {
                        if (pathLocation.state.notice.type == 1) {
                            return (
                                <span>News</span>
                            )
                        } else if (pathLocation.state.notice.type == 2) {
                            return (
                                <span>Events</span>
                            )
                        } else if (pathLocation.state.notice.type == 3) {
                                return (
                                    <span>Updates</span>
                                )
                            } 
                    })()}
                </h2>
                <div className="d-flex justify-content-center">
                    <div className="d-flex justify-content-center">
                        <div dangerouslySetInnerHTML={createMarkup(pathLocation.state.notice.description)}></div>
                    </div>
                </div>
                </div>
            </section>
        </div>
    )
}