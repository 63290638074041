import React, {useEffect} from "react";
import ControlPanelTransactionsService from "./service/ControlPanelTransactionsService";
import GetDateLocale from "../../../services/GetDateLocale";
import dateFormat from "dateformat";
import Pagination from 'react-bootstrap/Pagination'

const ControlPanelTransactions = () => {

    const {  
        handleTransactions,
        handlePage,
        transactions,
        page,
        totalPages
    } = ControlPanelTransactionsService()

    useEffect(() => {
        handleTransactions(page);
    },[]);

    useEffect(() => {
        handleTransactions(page);
    },[page]);

    let items = [];
    items.push(<Pagination.Item key={'left'} onClick={handlePage(1)}><span aria-hidden="true">«</span></Pagination.Item>);
    items.push(<Pagination.Item key={'left1'} onClick={handlePage(page - 1)}><span aria-hidden="true">‹</span></Pagination.Item>);
    items.push(
        <Pagination.Item activeLabel={false} key={page} onClick={handlePage(1)} active={true}>
        {page}
        </Pagination.Item>
    );
    if( page !== totalPages && totalPages > 0){
        items.push(
            <Pagination.Item activeLabel={false} key={'total1'} onClick={handlePage(totalPages)} active={true}>
            {totalPages}
            </Pagination.Item>
        );
    }
    items.push(<Pagination.Item key={'rigth'} onClick={handlePage(page + 1)}><span aria-hidden="true">›</span></Pagination.Item>);
    items.push(<Pagination.Item key={'rigth1'} onClick={handlePage(totalPages)}><span aria-hidden="true">»</span></Pagination.Item>);

    return(
        <div>
        <h1 className="title">Donation History</h1>
        <hr></hr>
        <div className="contentForm">
        <hr></hr>
        <div className="table-responsive">
            <table className="table-system">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Cash</th>
                    <th>Data</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {transactions.Items.map((item, key) => (
                <tr key={key}>
                    <td>{item.Id}</td>
                    <td>{item.cash + item.cashBonus}</td>
                    <td>{dateFormat(item.createdAt, GetDateLocale())}</td>
                    {(() => {
                        if (item.status == "Aprovado") {
                            return (
                                <td className="text-success">Approved</td>
                            )
                            } else if (item.status == "Pendente") {
                            return (
                                <td className="text-info">Pending</td>
                            )
                            } else if (item.status == "Recusado") {
                                return (
                                    <td className="text-danger">Refused</td>
                                )
                        } 
                    })()}
                </tr>
                ))}
                </tbody>
            </table>
        </div>
        </div>
        <br/>
        <Pagination size="sm">{items}</Pagination>
    </div>
    )
}

export default ControlPanelTransactions;