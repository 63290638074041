import React, { useEffect } from "react";
import { ControlPanelUpdateCashAccountService } from "./service/ControlPanelUpdateCashAccountService";
import ValidateForm from "../../../../../services/ValidateForm";
import { AccountInformationData } from "./AccountInformationData";
import { getSearch, getSearchAccount } from "../../../../../services/SearchData";

export const ControlPanelUpdateCashAccount = ({submitForm}) => {

    const {
        handleChange,
        handleSubmitFindAccount,
        errors,
        values,
        accountData
    } = ControlPanelUpdateCashAccountService(
        submitForm,
        ValidateForm
    );

    return(
        <>
        <div>
            <h1 className="title">UPDATE CASH ACCOUNT</h1>
            <div className="contentForm">
                <div className="col-12">
                    <form onSubmit={handleSubmitFindAccount}>
                        <div className={values.Search === "" ? "formGroup" : "formGroup on"}>
                            <label>FamilyName or Username</label>
                            <input type="text" name="Search"
                            value={values.Search}
                            onChange={handleChange}
                            />
                        </div>
                        {errors.Search && 
                        <div className="form-msg form-error">
                            <i className="sp-form noti"></i> {errors.Search}
                        </div>
                        }
                        <button className="custom-button">Find</button>
                    </form>
                </div>
                {accountData ? 
                    <AccountInformationData accountData={accountData} />
                    :
                    <>
                    <AccountInformationData accountData={getSearchAccount()} />
                    </>
                }
            </div>
        </div>
        </>
    )
}