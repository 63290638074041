import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CashShopService from "./service/CashShopService";

export const CashShopCategory = () => {

    const { 
        handleCashShopCategories, 
        categories, 
    } = CashShopService();

    useEffect(() => {
        handleCashShopCategories()
    },[])

    return(
        <div className="todosItens">
            <ul className="mainmenu">
                {categories.map((category, key) => (
                    <div key={key}>
                        {/* <li><a href="">{category.name} <span>(62)</span></a></li>
                        <li><a href="">About</a></li> */}
                        <li><Link to={"/Shop/" + category.Id + "/" + 0}>{category.name}</Link>
                            {category.SubCategories.map((subcategory, subKey) => (
                              <div key={subKey}>
                                <ul className="submenu">
                                    <li><Link to={"/Shop/" + category.Id + "/" + subcategory.Id}>{subcategory.name}</Link></li>
                                </ul>
                              </div>
                            ))}
                        </li>
                    </div>
                ))}
            </ul>
        </div>
    )
}