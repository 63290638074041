import React, {useState} from "react";
import Api from "../../../../services/Api";

const ControlPanelIndicationService = () => {

    const [transactions, setTransactions ] = useState(
        {
            Items : []
        }
    );
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handlePage = pageId => async e => {

        if(pageId < 1)
            return;

        if(pageId > totalPages)
            return;

        setPage(pageId);
    }
  
    const handleTransactions = async pageId => {
        await Api.get("/Account/GetIndicationTransactions/" + pageId)
        .then((response) =>{
            setTransactions(response.data.accountIndications);
            setTotalPages(response.data.accountIndications.PageCount);
           })
            .catch((err) => {
        });
    }

    const handleConvertTransaction = async (e, paymentId, type) => {
        e.preventDefault()

        await Api.get("/Account/SendPaymentIndication/" + paymentId + "/" + type)
        .then((response) =>{

        })
        .catch((err) => {
        });
    }
    
    return { 
            handleTransactions,
            handlePage,
            handleConvertTransaction,
            transactions,
            page,
            totalPages
           };
}

export default ControlPanelIndicationService;