import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { CashShopCategory } from "./CashShopCategory"
import { CashShoFindItem } from "./CashShopFindItem"
import CashShopService from "./service/CashShopService"
import { getAccount } from "../../../services/Auth"
import { useLocation } from "react-router-dom";

export const CashShopMain = ({children}) => {

    const location = useLocation();

    const {
        handleShopCartItemCount,
        handleFirstPage,
        shopCartCount,
        firstCat
    } = CashShopService()

    useEffect(() => {
        handleShopCartItemCount()
        handleFirstPage()
    }, [])

    useEffect(() => {
        if(typeof firstCat.Id != "undefined"){
           if(location.pathname.match("/Shop/" + firstCat.Id + "/" + 0))
                return;

           window.location.href = "/Shop/" + firstCat.Id + "/" + 0
        }
    }, [firstCat])

    useEffect(() => {
        if(localStorage.getItem("cart") == "undefined"){
            localStorage.removeItem('cart')
            window.location.reload()
        }
    },[localStorage.getItem("cart")])
    
    return(
        <div>
            <div className="page-layout">
                <div className="breadcrumbs">
                <h1>Cash-Shop</h1>
                </div>
            </div>
            <section className="downloads">
                <div className="container">
                <div className="content">
                    <div className="marketTopBlock">
                        <div className="marketTop flex-s-c">
                        <h2 className="page-title m-0">
                            <i className="icon icon-page-title" />
                            Cash-Shop
                        </h2>
                        <div className="marketTopRight flex-c">
                            <div className="totlBlock flex-c">
                            <div className="totl">
                                <p>Cash Balance:</p>
                                <span>
                                <i className="icon icon-ic" /> {getAccount().cash}
                                </span>
                            </div>
                            </div>
                            <div className="totlBittons flex-c">
                            <Link to="/Shop/Cart" className="button-not-bg">
                                <i className="icon icon-market-white" /> Shopping cart - ( {shopCartCount} )
                            </Link>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/*marketTopBlock*/}
                    <div className="marketContent">
                        <div className="marketContent-title flex-s-c">
                        <h2 className="page-title m-0">
                            <i className="icon icon-page-title" />
                        </h2>
                        {/* Find */}
                        {/* TODO: */}
                        {/* <CashShoFindItem /> */}
                        </div>
                        {/*marketContent-title*/}
                        <div className="itensFlex flex">
                            <CashShopCategory />
                            {/*itenBlock*/}
                            {/* <CashShopItem /> */}
                            {children}
                            {/*itenBlocks*/}
                        </div>
                        {/*itemsFlex*/}
                    </div>
                    </div>
                </div>
            </section>
        </div>
    )
}