import { useState } from "react";
import Api from "../../../../../services/Api";
import Swal from "sweetalert2";

const RegisterService = (callback, validate) => {

    const [errors, setErrors] = useState([]);

    const [values, setValues] = useState({
        Username: "",
        Password : "",
        ConfirmPassword : "",
        Email : "",
        BirthDate : "",
        RegFont : ""
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });
    };

    const [isVerified, setChecked] = useState(false);

    const handleCaptchaChange = e => {
        if(e){
            setChecked(true);
        }
    }

    const handleSubmitRegister = async e => {
        e.preventDefault();

        const form_data = new FormData();
        form_data.append('Username', values.Username);
        form_data.append('Password', values.Password);
        form_data.append('ConfirmPassword', values.ConfirmPassword);
        form_data.append('Email', values.Email);
        form_data.append('BirthDate', values.BirthDate);
        form_data.append('RegFont', values.RegFont);
        
        setErrors("");

        if(isVerified == false){
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Information',
            text : 'Captcha invalid!',
            showConfirmButton: false,
            timer: 16500,
            background: 'rgba(43, 43, 46, 0.8)'
          })
          return;
        }

        await Api.post("/Auth/Register", form_data)
        .then((response) =>{
        })
        .catch((err) => {
          if(err.response){
            var errors = err.response.data.errors;
            setErrors(validate(errors));
          }
        });
    };

    return { handleChange, handleSubmitRegister, handleCaptchaChange,
        values, errors, isVerified};

}

export default RegisterService;