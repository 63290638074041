import React, { useEffect, useState } from "react";
import { ControlPanelUpdateCashAccountService } from "./service/ControlPanelUpdateCashAccountService";
import ValidateForm from "../../../../../services/ValidateForm";

export const AccountInformationData = (info, {submitForm}) => {

    const [changeInfo, setChangeInfo] = useState(0)

    const {
        handleChange,
        handleUpdateAccountCash,
        errors,
        values
    } = ControlPanelUpdateCashAccountService(
        submitForm,
        ValidateForm
    );


    function ChangeStatusForButton(id){
        setChangeInfo(id)
    }

    return(
        <>
        {info.accountData != null ? 
        <>
        <br></br>
        <br></br>
        <br></br>
        <h4 className="title">ACCOUNT INFORMATION</h4>
        <br></br>
        <div className="option">
            <span>ID</span>
            <strong>{info.accountData.Id}</strong>
        </div>
        <div className="option">
            <span>UUID</span>
            <strong>{info.accountData.UUID}</strong>
        </div>
        <div className="option">
            <span>Email</span>
            <strong>{info.accountData.email}</strong>
        </div>
        <div className="option">
            <span>Username</span>
            <strong>{info.accountData.username}</strong>
        </div>
        <div className="option">
            <span>Cash</span>
            {changeInfo == 0 ?
                <>
                <strong>{info.accountData.cash}</strong>
                <button onClick={() => ChangeStatusForButton(5)} className="formButton">Change</button>
                </>
                :
                <>
                </>
            }

            {changeInfo == 5 ?
            <>
            <strong>{info.accountData.cash}</strong>
            <form onSubmit={handleUpdateAccountCash(info.accountData.Id)}>
                <div className={values.Cash === "" ? "formGroup" : "formGroup on"}>
                    <label>Cash</label>
                    <input type="text" name="Cash"
                    value={values.Cash}
                    onChange={handleChange}
                    />
                </div>
                {errors.Cash && 
                <div className="form-msg form-error">
                    <i className="sp-form noti"></i> {errors.Cash}
                </div>
                }
                <button className="formButton">Update</button>
            </form>
            </>
                :
            <></>
            }
        </div>
        </>
        : 
        <></>}
        </>
    )
}