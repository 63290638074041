import Swal from "sweetalert2";
import Api from "../../../../../services/Api";
import {useState} from "react";
import { login, account, user, logout } from "../../../../../services/Auth";

const SigninService = (callback, validate) => {

    const [errors, setErrors] = useState([]);

    const [values, setValues] = useState({
        Username: "",
        Password : "",
        isVerified : false
    });

    const [isVerified, setChecked] = useState(false);

    const handleCaptchaChange = e => {
        if(e){
            setChecked(true);
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });
    };

    const handleSubmitLogin = async e => {
        e.preventDefault();

        const form_data = new FormData();
        form_data.append('Username', values.Username);
        form_data.append('Password', values.Password);
        
        setErrors("");

        if(isVerified == false){
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Information',
            text : 'Recaptcha invalid!',
            showConfirmButton: false,
            timer: 16500,
            background: 'rgba(43, 43, 46, 0.8)'
          })
          return;
        }

        await Api.post("/Auth/Autenticate", form_data)
        .then((response) =>{
            login(response.data.acessToken);
            account(response.data.account);
            user(response.data.user)
            window.location.href = "/ControlPanel/AccountDetails";
        })
        .catch((err) => {
          if(err.response){
            var errors = err.response.data.errors;
            setErrors(validate(errors));
          }
        });
    };

    return { handleChange, handleSubmitLogin, handleCaptchaChange, 
        values, errors};

}

export default SigninService;