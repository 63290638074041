import React, { useEffect } from "react";
import CashShopService from "./service/CashShopService";
import ValidateForm from "../../../services/ValidateForm";
import { Link } from "react-router-dom";
import Pagination from 'react-bootstrap/Pagination'

export const CashShopItem = (props) => {

    const { 
        handleCashShopItems,
        handlePage,
        itemList,
        page,
        totalPages
    } = CashShopService() 

    let categoryId = props.match.params.categoryId
    let subCategoryId = props.match.params.subCategoryId

    const date = new Date()

    useEffect(() => {
        if(typeof subCategoryId == 'undefined')
            subCategoryId = 0

        handleCashShopItems(1, categoryId , subCategoryId)
    },[categoryId])

    useEffect(() => {
        if(typeof subCategoryId == 'undefined')
            subCategoryId = 0

        handleCashShopItems(1, categoryId , subCategoryId)
    },[subCategoryId])

    useEffect(() => {
        if(typeof subCategoryId == 'undefined')
            subCategoryId = 0

        handleCashShopItems(page, categoryId , subCategoryId)
    },[]);

    useEffect(() => {
        if(typeof subCategoryId == 'undefined')
            subCategoryId = 0

        handleCashShopItems(page, categoryId , subCategoryId)
    },[page]);

    let items = [];
    items.push(<Pagination.Item key={'left'} onClick={handlePage(1)}><span aria-hidden="true">«</span></Pagination.Item>);
    items.push(<Pagination.Item key={'left1'} onClick={handlePage(page - 1)}><span aria-hidden="true">‹</span></Pagination.Item>);
    items.push(
        <Pagination.Item activeLabel={false} key={page} onClick={handlePage(1)} active={true}>
        {page}
        </Pagination.Item>
    );
    if( page !== totalPages && totalPages > 0){
        items.push(
            <Pagination.Item activeLabel={false} key={'total1'} onClick={handlePage(totalPages)} active={false}>
            {totalPages}
            </Pagination.Item>
        );
    }
    items.push(<Pagination.Item key={'rigth'} onClick={handlePage(page + 1)}><span aria-hidden="true">›</span></Pagination.Item>);
    items.push(<Pagination.Item key={'rigth1'} onClick={handlePage(totalPages)}><span aria-hidden="true">»</span></Pagination.Item>);

    return(
        <>
        <div className="itenBlocks flex">
            {itemList.Items.map((item, key) => (
            <div key={key} className="itenBlock">
                <div className="itenBlock-active">
                    {(item.createdAt < (date + 2)) ?
                        <span className="corner corner-purple">
                            <span>{(item.createdAt < (date + 2)) ? 'N' : ''}</span>
                        </span>
                        :
                        <div></div>
                    }
                    <div className="itenBlock-active_img">
                    <img style={{ width : "44px", height: "44px"}} src={"/assets/images/item/" + item.itemId + ".png"} alt="" />
                    </div>
                    <div className="itenBlock-active_title">{item.name}</div>
                    <div className="itenBlock-active_price">
                    <i className="icon icon-ic" /> {item.price * item.count}
                    </div>
                </div>
                <div className="itenBlock-hover flex-c-c">
                    <div className="itenBlock-hover_block">
                    <Link className="button-not-bg" to={{
                        pathname:"/Shop/Details",
                        state:item
                    }}>
                    Details
                    </Link>
                    </div>
                </div>
            </div>
            ))}
        </div>
        
        <div className="marketContent-title flex-s-c">
            <div className="totalTitle-block" style={{position:'relative', left:'243px', bottom:'40px'}}>
                <Pagination size="sm">{items}</Pagination>
            </div>
        </div>
        </>
    )
}