import React,{useState} from "react";
import Api from "../../../../services/Api";

export const NoticeService = () => {

    const [notices, setNotices ] = useState(
        {
            Items : []
        }
    );
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [fixedNotice, setFixedNotice] = useState();

    const handlePage = pageId => async e => {

        if(pageId < 1)
            return;

        if(pageId > totalPages)
            return;

        setPage(pageId);
    }
    
  
    const handleNotices = async (pageId, type) => {
        var pageItems = 5;

        await Api.get("/MainNotice/GetAll/" + pageId + "/" + pageItems + "/" + type)
        .then((response) =>{
            setNotices(response.data.notices.Result);
            setTotalPages(response.data.notices.Result.PageCount);
           })
            .catch((err) => {
        });
    }

    const handleFixedNotice = async () => {
        await Api.get("/MainNotice/GetFixedNotice")
        .then((response) =>{
            setFixedNotice(response.data.notice);
           })
            .catch((err) => {
        });
    }
    
    return {
        handleNotices,
        handlePage,
        handleFixedNotice,
        notices,
        page,
        totalPages,
        fixedNotice
    }
}
