import React, {useState} from "react";
import Api from "../../../../services/Api";

const ControlPanelDonateService = (callback, validate) => {

    const [errors, setErrors] = useState({});
    const [cashPackets, setCashPackes ] = useState([]);
    const [cashPacketFonts, setCashPacketFonts ] = useState([]);

    const [values, setValues] = useState({
        cashPacketFontId: "",
        cashPacketMethod: ""
    });
  
    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
        ...values,
        [name]: value
        });
    };

    const handleCashPacketFonts = async e => {
        await Api.get("/Donate/GetCashPacketFonts")
        .then((response) =>{
            setCashPacketFonts(response.data);
        })
        .catch((err) => {

        });
    }

    const handleCashPackets = async e => {

        if(values.cashPacketFontId === "")
            return;

        const form_data = new FormData();
        form_data.append('cashPacketFontId', values.cashPacketFontId);

        setErrors("");
        setCashPackes([]);

        await Api.post("/Donate/GetCashPackets", form_data)
        .then((response) =>{
            setCashPackes(response.data);
        })
        .catch((err) => {
          if(err.response){
            var errors = err.response.data.errors;
            setErrors(validate(errors));
          }
        });
    }

    const handleBuy = id => async e => {
        e.preventDefault(); 

        await Api.get("/Donate/" + values.cashPacketMethod + "/" + id)
        .then((response) =>{

            if(values.cashPacketMethod == "CreatePayPalPayment"){
                window.location.href = response.data.links[1].href;
            }

            if(values.cashPacketMethod == "BuyMpAction"){
                window.location.href = response.data.Result.InitPoint; 
            }
               
        })
        .catch((err) => {
        });
    }
    
    return  { 
             handleChange,
             handleCashPackets, 
             handleCashPacketFonts,
             handleBuy,
             values, 
             errors, 
             cashPackets, 
             cashPacketFonts 
           };
}

export default ControlPanelDonateService;