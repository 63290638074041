import axios from "axios";
import Swal from "sweetalert2";
import { getToken, logout } from "./Auth";
import { BASE_URL, BASE_LANG } from "../Configuration";

const Api = axios.create({
  baseURL: BASE_URL + BASE_LANG
});

Api.interceptors.request.use(async config => {
  const token = getToken();
  // config.headers['Access-Control-Allow-Headers'] = '*';
  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }
  return config;
});

Api.interceptors.response.use(function (response) {
  
  if(response.status === 200){
    if(response.data.code === "general_success"){
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Successful',
        text : response.data.message,
        showConfirmButton: false,
        timer: 6500,
        background: 'rgba(43, 43, 46, 0.8)'
      })
    }
    if(response.data.code === "general_error"){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Error!',
        text : response.data.message,
        showConfirmButton: false,
        timer: 6500,
        background: 'rgba(43, 43, 46, 0.8)'
      })
    }
  }
  return response;
}, function (err) {
 
  if(err.response.status === 401){
    logout();
    window.location.reload();
  }else if(err.response.status === 403){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Information',
        text : "Unauthorized!",
        showConfirmButton: false,
        timer: 6500,
        background: 'rgba(43, 43, 46, 0.8)'
      })
  }else if(err.response.status === 400){
    if(err.response.data.errors){
      if(err.response.data.errors.general_error){
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Information',
          text : err.response.data.errors.general_error[0],
          showConfirmButton: false,
          timer: 6500,
          background: 'rgba(43, 43, 46, 0.8)'
        })
      }
    }
  }
  return Promise.reject(err);
});

export default Api;