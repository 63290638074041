import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { isAuthenticated } from "./services/Auth";

// Layout
import Main from "./components/layout/Main";
//

// Pages
import Home from "./components/pages/home/Home";
import Register from "./components/pages/auth/register/Register";
import { Notice } from "./components/pages/notice/Notice";
import { NoticeRead } from "./components/pages/notice/NoticeRead";
import { Download } from "./components/pages/download/Download";

// Control Panel
import ControlPanelMain from "./components/pages/controlPanel/ControlPanelMain";
import ControlPanelAccountDetails from "./components/pages/controlPanel/ControlPanelAccountDetails";
import ControlPanelAccountChangePass from "./components/pages/controlPanel/ControlPanelAccountChangePass";
import ControlPanelDonate from "./components/pages/controlPanel/ControlPanelDonate";
import ControlPanelTransactions from "./components/pages/controlPanel/ControlPanelTransactions";
import ControlPanelNews from "./components/pages/controlPanel/admin/news/ControlPanelNews";
import ControlPanelNewsAdd from "./components/pages/controlPanel/admin/news/ControlPanelNewsAdd";
import ControlPanelNewsEdit from "./components/pages/controlPanel/admin/news/ControlPanelNewsEdit";
import ControlPanelNewsDetails from "./components/pages/controlPanel/admin/news/ControlPanelNewsDetails";
import ControlPanelRecoverGiftCode from "./components/pages/controlPanel/ControlPanelRecoverGiftCode";
import { ControlPanelIndicationCash } from "./components/pages/controlPanel/ControlPanelIndicationCash";
import { ControlPanelVote } from "./components/pages/controlPanel/ControlPanelVote";
import { ControlPanelUpdateCashAccount } from "./components/pages/controlPanel/admin/cashaccount/ControlPanelUpdateCashAccount";
// 

// Cash Shop
import { CashShopMain } from "./components/pages/cashshop/CashShopMain";
import { CashShopItem } from "./components/pages/cashshop/CashShopItem";
import { CashShopItemDetails } from "./components/pages/cashshop/CashShopItemDetails";
import { CashShopCart } from "./components/pages/cashshop/CashShopCart";
//

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);


export default () => {
  return (
  <Router>
    <Route>
      <Main>
        <Switch>
          <Route exact path='/' exact component={Home} />
          <Route exact path='/Register' component={Register} />
          <Route exact path='/Notice' component={Notice} />
          <Route exact path='/Notice/Read' component={NoticeRead} />
          <Route exact path='/Download' component={Download} />
          <Route path="/Shop">
              <CashShopMain>
                <PrivateRoute exact path='/Shop/:categoryId/:subCategoryId' exact component={CashShopItem} />
                <PrivateRoute exact path='/Shop/Details' exact component={CashShopItemDetails} />
                <PrivateRoute exact path='/Shop/Cart' exact component={CashShopCart} />
                <PrivateRoute exact path='/Shop/FindItem' exact component={CashShopItem} />
              </CashShopMain>
          </Route>
           <Route path="/ControlPanel">
              <ControlPanelMain>
                <PrivateRoute exact path='/ControlPanel/AccountDetails' exact component={ControlPanelAccountDetails} />
                <PrivateRoute exact path='/ControlPanel/ChangePassword' exact component={ControlPanelAccountChangePass} />
                <PrivateRoute exact path='/ControlPanel/Donate' exact component={ControlPanelDonate} />
                <PrivateRoute exact path='/ControlPanel/Transactions' exact component={ControlPanelTransactions} />
                <PrivateRoute exact path='/ControlPanel/GiftCode' exact component={ControlPanelRecoverGiftCode} />
                <PrivateRoute exact path='/ControlPanel/CashIndications' exact component={ControlPanelIndicationCash} />
                <PrivateRoute exact path='/ControlPanel/News' exact component={ControlPanelNews} />
                <PrivateRoute exact path='/ControlPanel/News/Add' exact component={ControlPanelNewsAdd} />
                <PrivateRoute exact path='/ControlPanel/News/Edit' exact component={ControlPanelNewsEdit} />
                <PrivateRoute exact path='/ControlPanel/News/Details' exact component={ControlPanelNewsDetails} />
                <PrivateRoute exact path='/ControlPanel/Vote' exact component={ControlPanelVote} />
                <PrivateRoute exact path='/ControlPanel/CashAccountUpdate' exact component={ControlPanelUpdateCashAccount} />
              </ControlPanelMain>
            </Route>
            <Route path='*' exact component={Home} />
        </Switch>
      </Main>
    </Route>
  </Router>
  )
} 