import React, {useState} from "react";
import Api from "../../../../../../services/Api";
import Swal from "sweetalert2";
import { useLocation } from 'react-router-dom'
import { convertHtmlToPlainText } from "../../../../../../services/Functions";

const ControlPanelNewsService = (callback, validate) => {

    const pathLocation = useLocation();
    
    const [errors, setErrors] = useState([]);

    const [values, setValues] = useState({
        Id: (pathLocation.state != undefined) ? pathLocation.state.Id : "",
        Name: (pathLocation.state != undefined) ? pathLocation.state.name : "",
        Type : (pathLocation.state != undefined) ? pathLocation.state.type : "",
        IsActivated : (pathLocation.state != undefined) ? pathLocation.state.isActivated : "",
        IsFixed : (pathLocation.state != undefined) ? pathLocation.state.isFixed : "",
        ImageIcon : (pathLocation.state != undefined) ? pathLocation.state.imageIcon : "",
        Description : (pathLocation.state != undefined) ? convertHtmlToPlainText(pathLocation.state.description) : ""
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });
    };

    const handleSubmitSaveNotice = async e => {
        e.preventDefault();

        const form_data = new FormData();
        form_data.append('Name', values.Name);
        form_data.append('Type', values.Type);
        form_data.append('IsActivated', values.IsActivated);
        form_data.append('IsFixed', values.IsFixed);
        form_data.append('ImageIcon', values.ImageIcon);
        form_data.append('Description', values.Description);
        
        setErrors("");

        await Api.post("/Notice/Save", form_data)
        .then((response) =>{

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Information',
            text : response.data.message,
            showConfirmButton: false,
            timer: 6500,
            background: 'rgba(43, 43, 46, 0.8)'
        })

        setValues({
            Name: "",
            Type : "",
            IsActivated : "",
            IsFixed : "",
            ImageIcon : "",
            Description : ""
        });

        })
        .catch((err) => {
            if(err.response){
            var errors = err.response.data.errors;
            setErrors(validate(errors));
            }
        });
    }

    const handleSubmitEditNotice = async e => {
        e.preventDefault();

        const form_data = new FormData();
        form_data.append('Id', values.Id);
        form_data.append('Name', values.Name);
        form_data.append('Type', values.Type);
        form_data.append('IsActivated', values.IsActivated);
        form_data.append('IsFixed', values.IsFixed);
        form_data.append('ImageIcon', values.ImageIcon);
        form_data.append('Description', values.Description);
        
        setErrors("");

        await Api.post("/Notice/Edit", form_data)
        .then((response) =>{

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successful',
            text : response.data.message,
            showConfirmButton: false,
            timer: 6500,
            background: 'rgba(43, 43, 46, 0.8)'
        })

        })
        .catch((err) => {
            if(err.response){
            var errors = err.response.data.errors;
            setErrors(validate(errors));
            }
        });
    }

    const handleDelete = (e, Id) => {
        e.preventDefault();
        
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false,
                background: 'rgba(43, 43, 46, 0.8)',
            })
          
            swalWithBootstrapButtons.fire({
            title: 'Você tem certeza?',
            text: "Não será possivel reverter essa ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Não, cancele!',
            reverseButtons: true
            }).then((result) => {
            if (result.isConfirmed) {

                Api.get("/Notice/Delete/" + Id)
                .then((response) =>{
                    swalWithBootstrapButtons.fire(
                        'Deletado!',
                        'O seu registro foi deletado com sucesso.',
                        'success'
                    )

                    handleNotices(page);
                })
                .catch((err) => {
                    swalWithBootstrapButtons.fire(
                        'Cancelado',
                        'Falha ao deletar!',
                        'error'
                    )
                });
            } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                'Cancelado',
                'Ação cancelada',
                'error'
                )
            }
        })
    }

    const [notices, setNotices ] = useState(
        {
            Items : []
        }
    );
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handlePage = pageId => async e => {

        if(pageId < 1)
            return;

        if(pageId > totalPages)
            return;

        setPage(pageId);
    }
  
    const handleNotices = async pageId => {
        var pageItems = 3;

        await Api.get("/Notice/GetAll/" + pageId + "/" + pageItems)
        .then((response) =>{
            setNotices(response.data.notices);
            setTotalPages(response.data.notices.PageCount);
           })
            .catch((err) => {
        });
    }

    return {
        handleChange,
        handleSubmitSaveNotice,
        handleSubmitEditNotice,
        handleNotices,
        handleDelete,
        handlePage,
        errors,
        values,
        page,
        totalPages,
        notices
    }
}

export default ControlPanelNewsService;