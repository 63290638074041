import React, { useEffect, useState } from "react";
import { getAccount } from "../../../services/Auth";
import ControlPanelAccountService from "./service/ControlPanelAccountService";
import dateFormat from "dateformat";
import GetDateLocale from "../../../services/GetDateLocale";
import ControlPanelGlobalData from "./service/ControlPanelGlobalData";
import ValidateForm from "../../../services/ValidateForm";

const ControlPanelAccountDetails = ({submitForm}) => {

    const { 
        handleAccountActivate,
        handleChange,
        handleSubmitSaveIndication,
        errors,
        values,
        accounts
    } = ControlPanelAccountService(
        submitForm,
        ValidateForm
    );

    const { getAccountData } = ControlPanelGlobalData()

    useEffect(async () => {
        getAccountData()
    },[])

    return(
        <div>
            <h1 className="title">Account information</h1>
            <div className="contentForm">
                <div className="option">
                    <span>Email</span>
                    <strong>{getAccount().email}</strong>
                    {/* <button className="formButton">Update</button> */}
                </div>
                <div className="option">
                    <span>User</span>
                    <strong>{getAccount().username}</strong>
                    {/* <button className="formButton">Update</button> */}
                </div>
                <div className="option">
                    <span>Account Status</span>
                    <strong>{getAccount().isActivated === 0 ? "not activated" : "Activated"}</strong>
                    {getAccount().isActivated === 0 && 
                      <button onClick={handleAccountActivate} className="formButton">Activate</button>
                    }
                </div>
                <div className="option">
                    <span>Creation date</span>
                    <strong>{dateFormat(getAccount().createdAt, "dd/mm/yyyy")}</strong>
                    {/* <button className="formButton">Update</button> */}
                </div>
                <div className="option">
                    <span>Cash</span>
                    <strong>{getAccount().cash}</strong>
                    {/* <button className="formButton">Update</button> */}
                </div>

                
                {/*<div className="option">
                    <span>Recommendation</span>
                    {
                        getAccount().accountIndicationUsername != null ?
                        <strong>{getAccount().accountIndicationUsername}</strong>
                        :
                        <form onSubmit={handleSubmitSaveIndication}>
                            <strong>
                                <div className={values.accountIndication === "" ? "formGroup" : "formGroup on"}>
                                    <label>Enter the username of the person who referred you</label>
                                    <input list="users" type="text" name="accountIndication"
                                        value={values.accountIndication}
                                        onChange={handleChange}
                                    />
                                    <datalist id="users">
                                        {accounts.map((item, key) => (
                                            <option key={key} value={item.username}></option>
                                        ))}
                                    </datalist>
                                </div>
                                {errors.accountIndication && 
                                <div className="form-msg form-error">
                                    <i className="sp-form noti"></i> {errors.accountIndication}
                                </div>}
                                <button style={{left:"252px", top:"-80px"}} className="custom-button">Save</button>
                            </strong>
                            <p>
                                * Remember that your Referrer will receive 10% of all your donations.
                            </p>
                        </form>
                    }
                </div>*/}
            </div>
        </div>
    )
}

export default ControlPanelAccountDetails;