export const lastSearch = (search, data) => {
    localStorage.setItem("search", search);
    localStorage.setItem("accountSearch", JSON.stringify(data));
};

export const getSearch = () => {
    const data = localStorage.getItem("search");
    return data;
};

export const getSearchAccount = () => {
    let data = null
    if(typeof(localStorage.getItem("accountSearch"))){
        data = JSON.parse(localStorage.getItem("accountSearch"));
    }
    return data;
};