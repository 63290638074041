import React, {useState, Component } from "react";
import { Link } from "react-router-dom";
import ControlPanelNewsService from "./service/ControlPanelNewsService";
import ValidateForm from "../../../../../services/ValidateForm";

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const ControlPanelNewsAdd = ({submitForm}) => {

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    
    const  [convertedContent, setConvertedContent] = useState({});

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
        values.Description = currentContentAsHTML;
    }

    const createMarkup = (html) => {
        return  {
            __html: DOMPurify.sanitize(html)
        }
    }
    
    const {
        handleChange,
        handleSubmitSaveNotice,
        errors,
        values
    } = ControlPanelNewsService(
        submitForm,
        ValidateForm
    );

    return(
        <div>
            <h1 className="title">Manage News - Add</h1>
            
            <hr></hr>
            <div className="contentForm">               
                <Link to="/ControlPanel/News">
                    <button className="custom-button">News</button>
                </Link>

                <form onSubmit={handleSubmitSaveNotice}> 
                <br/>
                <button className="custom-button">Save</button>

                <hr></hr>

                <div className={values.Name === "" ? "formGroup" : "formGroup on"}>
                      <label>Nome</label>
                      <input type="text" name="Name"
                        value={values.Name}
                        onChange={handleChange}
                      />
                  </div>
                  {errors.Name && 
                    <div className="form-msg form-error">
                        <i className="sp-form noti"></i> {errors.Name}
                    </div>
                  }

                <div className={values.Type === "" ? "formGroup" : "formGroup on"}>
                    <select name="Type"
                      value={values.Type}
                      onChange={handleChange}
                    >
                        <option value="" disabled>Tipo</option>
                        <option value="1">News</option>
                        <option value="2">Event</option>
                        <option value="3">Update</option>
                    </select>
                </div>
                {errors.Type && 
                  <div className="form-msg form-error">
                      <i className="sp-form noti"></i> {errors.Type}
                  </div>
                }

                <div className={values.IsActivated === "" ? "formGroup" : "formGroup on"}>
                    <select name="IsActivated"
                      value={values.IsActivated}
                      onChange={handleChange}
                    >
                        <option value="" disabled>Activated</option>
                        <option value="1">Activated</option>
                        <option value="2">Disabled</option>
                    </select>
                </div>
                {errors.IsActivated && 
                  <div className="form-msg form-error">
                      <i className="sp-form noti"></i> {errors.IsActivated}
                  </div>
                }

                <div className={values.IsFixed === "" ? "formGroup" : "formGroup on"}>
                    <select name="IsFixed"
                      value={values.IsFixed}
                      onChange={handleChange}
                    >
                        <option value="" disabled>Highlighted</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                    </select>
                </div>
                {errors.IsFixed && 
                  <div className="form-msg form-error">
                      <i className="sp-form noti"></i> {errors.IsFixed}
                  </div>
                }

                <div className={values.ImageIcon === "" ? "formGroup" : "formGroup on"}>
                    <label>image link</label>
                    <input type="text" name="ImageIcon"
                    value={values.ImageIcon}
                    onChange={handleChange}
                    />
                </div>
                {errors.ImageIcon && 
                <div className="form-msg form-error">
                    <i className="sp-form noti"></i> {errors.ImageIcon}
                </div>
                }

                <div className="textAreaBody">
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={handleEditorChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                    />
                </div>
                <br/>
                {errors.Description && 
                    <div className="form-msg form-error">
                        <i className="sp-form noti"></i> {errors.Description}
                    </div>
                }
                
                {/* <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div> */}
                </form>
            </div>
        </div>       
    )
}

export default ControlPanelNewsAdd;