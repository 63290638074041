import React,{useState} from "react";
import Api from "../../../../services/Api";
import { updateAccountData } from "../../../../services/Auth";
import Swal from "sweetalert2";

const ControlPanelRecoverGiftCodeService = (callback, validate) => {

    const [errors, setErrors] = useState([]);

    const [values, setValues] = useState({
        GiftCode: ""
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });
    };

    const handleSubmitRecoverGiftCode = async e => {
        e.preventDefault();

        const form_data = new FormData();
        form_data.append('GiftCode', values.GiftCode);
        
        setErrors("");

        await Api.post("/Account/RecoverGiftCode", form_data)
        .then((response) =>{
            updateAccountData();
            setValues({
                GiftCode : ""
            });
        })
        .catch((err) => {
          if(err.response){
            var errors = err.response.data.errors;
            setErrors(validate(errors));
          }
        });
    };

    return {
        errors,
        values,
        handleChange,
        handleSubmitRecoverGiftCode
    }
}

export default ControlPanelRecoverGiftCodeService;