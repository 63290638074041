import React, { useState } from "react";
import { getCart, setCart, deleteCart } from "../../../../services/CashShopCart";
import Api from "../../../../services/Api";
import Swal from "sweetalert2";

const CashShopCartService = () => {

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [cartList, setCartList] = useState({
        Items :[]
    })
    const [totalBalance, setTotalBalance] = useState(0)
    const [buyed, setBuyed] = useState(false)

    const handlePage = pageId => async e => {

        if(pageId < 1)
            return;

        if(pageId > totalPages)
            return;

        setPage(pageId);
    }

    const handleCartItems = async () => {

        var items = getCart()

        await Api.get("Shop/GetCartItems/" + page + "/" + items)
        .then((response) =>{
           setCartList(response.data.Result)
           setTotalPages(response.data.Result.PageCount)
        })
        .catch((err) => {
        });
    }

    const handleCalcCartTotal = () => {
        let balance = 0
        cartList.Items.map((item, key) => {
            balance += item.Value.price * item.Value.count
        })
        setTotalBalance(balance)
    }

    const handleBuyCartItems = async (e) => {
        e.preventDefault()

        var items = getCart()

        await Api.get("Shop/BuyItems/" + items)
        .then((response) =>{
            setBuyed(true)
            deleteCart()
        })
        .catch((err) => {
        });

        setTimeout(() => {
            window.location.reload()
        }, 5000);
    }

    const handleRemove = (e, Id) => {
        e.preventDefault()

        let newItems
        var items = getCart().split(',')
        
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false,
            background: 'rgba(43, 43, 46, 0.8)',
        })

        Swal.fire({
            title: 'Are you sure?',
            text: "After confirming, it is not possible to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:"No",
            confirmButtonText: 'Yes!',
            background: 'rgba(43, 43, 46, 0.8)'
          }).then((result) => {
            if (result.isConfirmed) {

                items.map((item, key) => {
                    if(item != Id){
                        if(newItems){
                            newItems = newItems + "," + item
                        }else{
                            newItems = item
                        }
                    }
                })

                setCart(newItems)

                swalWithBootstrapButtons.fire(
                    'Delete!',
                    'Your record has been successfully deleted.',
                    'success'
                )
            }
        })

        setTimeout(() => {
            window.location.reload()
        }, 3000);
    }

    return{
        handleCartItems,
        handlePage,
        handleRemove,
        handleCalcCartTotal,
        handleBuyCartItems,
        cartList,
        totalPages,
        page,
        totalBalance,
        buyed
    }
}

export default CashShopCartService;