import React, { useEffect } from "react";

export const Download = () => {

    useEffect(() => {
        document.body.className = "page";
    },[])

    return(
        <div>
            <div className="page-layout">
                <div className="breadcrumbs">
                <h1>Downloads</h1>
                </div>
            </div>
            <section className="downloads">
                <div className="container">
                <h2 className="dTitle">
                    <span>Client</span>
                </h2>
                <div className="row downloadOptions">
                    <div className="col-md-6 ml-auto mr-auto">
                    <h3>Full Game Download</h3>
                    <p>Client Size (55GB)</p>
                    <div className="links">
                     <a target="_blank" href="https://drive.google.com/u/0/uc?id=1Kwh2mEo1DQmek0E0dO7_JHfY9kSo5ocl&export=download" className="dLinks google">
                        <img src="/assets/images/google-icon.png" alt />
                        <p>Google Disk</p>
                        </a>
                        <a target="_blank" href="https://nx42738.your-storageshare.de/s/fgjEjaAwHZGepBG/download/PrimeBD%20Reboot.torrent" className="dLinks torrent">
                        <img src="/assets/images/torrent-icon.png" alt />
                        <p>Torrent File</p>
                        </a>
                        <a target="_blank" href="https://1fichier.com/?rxcpxddefqz7zed79eh5" className="dLinks dropbox">
                        <img style={{width:"46px"}} src="https://cdn.discordapp.com/attachments/916803406344114197/954526288503275550/cropped-logo-removebg-preview_1.png" alt />
                        <p>1Fichier</p>
                        </a>
                        <a target="_blank" href="https://nx42738.your-storageshare.de/s/956ARLEER3WYtcq/download" className="dLinks dropbox">
                        <img style={{width:"46px"}} src="https://freeiconshop.com/wp-content/uploads/edd/download-cloud-flat.png" alt />
                        <p>Direct DL</p>
                        </a>
                        <a target="_blank" href="https://gofile.io/d/xeqpNM" className="dLinks dropbox">
                        <img style={{width:"46px"}} src="https://gofile.io/dist/img/logo-small.png" alt />
                        <p>GoFile.io</p>
                        </a>
                        <a target="_blank" href="https://qiwi.gg/file/3tmD1076-PrimeBDReboot" className="dLinks dropbox">
                        <img style={{width:"46px"}} src="https://qiwi.gg/static/images/og-image.png" alt />
                        <p>qiwi.gg</p>
                        </a>
                    </div>
                    </div>
                    <div className="col-md-6 ml-auto mr-auto">
                    <h3>Required programs to run the game</h3>
                    <br></br>
                    <a href="https://go.microsoft.com/fwlink/?LinkId=863262" className="dLinks dropbox">
                        <img style={{width:"90px"}} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQioBD3uFCcapU7mwqLmALOKvOlNexuBuaBCx4XtRuGPP-D01VBmO0XhOFqA9iQYOsVBCs&usqp=CAU" alt />
                        <p>NET Framework 4.7</p>
                    </a>
                    </div>
                </div>
                <div className="dTitle">
                    <span>System Requirements</span>
                </div>
                <div className="table-responsive">
                    <table className="table-system">
                    <thead>
                        <tr>
                        <td />
                        <td>Minimum</td>
                        <td>Recommended</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Disk Space</td>
                        <td>42 GB</td>
                        <td>50 GB</td>
                        </tr>
                        <tr>
                        <td>Processor</td>
                        <td>2.8 GHz</td>
                        <td>3.5 GHz</td>
                        </tr>
                        <tr>
                        <td>Memory, RAM</td>
                        <td>8 GB</td>
                        <td>16 GB</td>
                        </tr>
                        <tr>
                        <td>Video card</td>
                        <td>2 GB</td>
                        <td>8 GB</td>
                        </tr>
                        <tr>
                        <td>Internet</td>
                        <td>5 Mbps</td>
                        <td>10 Mbps</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </section>
        </div>
    )
}