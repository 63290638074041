import React,{useEffect} from "react";
import RegisterService from "./service/RegisterService";
import ValidateForm from "../../../../services/ValidateForm";
import { RECAPTCHA_KEY } from "../../../../Configuration";
import ReCAPTCHA from "react-google-recaptcha";

const Register = ({submitForm}) => {

    const {handleChange, handleSubmitRegister, handleCaptchaChange,
          values, errors, isVerified
    } = RegisterService(
       submitForm,
       ValidateForm
    );

    useEffect(() => {
      document.body.className = "page";
    },[])

    return(
    <div>
        <div className="page-layout">
          <div className="breadcrumbs">
            <h1>REGISTER</h1>
          </div>
        </div>
        <section className="downloads">
          <div className="container">
            <div className="dTitle">
              <span>ACCOUNT INFORMATION</span>
            </div>

            {/* FORM */}
            <div className="col-6">
            <form onSubmit={handleSubmitRegister}>
                <div className={values.Username === "" ? "formGroup" : "formGroup on"}>
                    <label>User</label>
                    <input type="text" name="Username"
                      value={values.Username}
                      onChange={handleChange}
                    />
                </div>
                {errors.Username && 
                  <div className="form-msg form-error">
                      <i className="sp-form noti"></i> {errors.Username}
                  </div>
                }
                <div className={values.Password === "" ? "formGroup" : "formGroup on"}>
                    <label>Password</label>
                    <input type="password" name="Password" 
                        value={values.Password}
                        onChange={handleChange}
                    />
                </div>
                {errors.Password && 
                  <div className="form-msg form-error">
                      <i className="sp-form noti"></i> {errors.Password}
                  </div>
                }
                <div className={values.ConfirmPassword === "" ? "formGroup" : "formGroup on"}>
                    <label>Confirm Password</label>
                    <input type="password" name="ConfirmPassword" 
                      value={values.ConfirmPassword}
                      onChange={handleChange}
                    />
                </div>
                {errors.ConfirmPassword && 
                  <div className="form-msg form-error">
                      <i className="sp-form noti"></i> {errors.ConfirmPassword}
                  </div>
                }
                <div className={values.Email === "" ? "formGroup" : "formGroup on"}>
                    <label>E-mail</label>
                    <input type="text" name="Email" 
                        value={values.Email}
                        onChange={handleChange}
                    />
                </div>
                {errors.Email && 
                  <div className="form-msg form-error">
                      <i className="sp-form noti"></i> {errors.Email}
                  </div>
                }
                <div className={values.BirthDate === "" ? "formGroup" : "formGroup on"}>
                    <label>Birthday  YYYY/MM/DD</label>
                    <input type="text" name="BirthDate" 
                      value={values.BirthDate}
                      onChange={handleChange}
                    />
                </div>
                {errors.BirthDate && 
                  <div className="form-msg form-error">
                      <i className="sp-form noti"></i> {errors.BirthDate}
                  </div>
                }
                <div className={values.RegFont === "" ? "formGroup" : "formGroup on"}>
                    <select name="RegFont"
                      value={values.RegFont}
                      onChange={handleChange}
                    >
                        <option value="" disabled>Registration Source</option>
                        <option value="Google">Google</option>
                        <option value="Friend">Friend</option>
                        <option value="Streamer">Streamer</option>
                        <option value="Youtube">Youtube</option>
                        <option value="Advertisement">Advertisement</option>
                    </select>
                </div>
                {errors.RegFont && 
                  <div className="form-msg form-error">
                      <i className="sp-form noti"></i> {errors.RegFont}
                  </div>
                }
                <div className="formGroup">
                    <ReCAPTCHA
                          sitekey={RECAPTCHA_KEY}
                          onChange={handleCaptchaChange}
                      />
                </div>
                <button className="custom-button">Register</button>
            </form>
            </div>
          </div>
        </section>
    </div>
    )
}

export default Register;