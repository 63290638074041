import React, {useEffect} from "react";
import dateFormat from "dateformat";
import Pagination from 'react-bootstrap/Pagination'
import GetDateLocale from "../../../../../services/GetDateLocale";
import ControlPanelNewsService from "./service/ControlPanelNewsService";
import { Link } from "react-router-dom";

const ControlPanelNews = () => {

    const {
        handleNotices,
        handlePage,
        handleDelete,
        page,
        totalPages,
        notices
    } = ControlPanelNewsService()

    useEffect(() => {
        handleNotices(page);
    },[]);

    useEffect(() => {
        handleNotices(page);
    },[page]);

    let items = [];
    items.push(<Pagination.Item key={'left'} onClick={handlePage(1)}><span aria-hidden="true">«</span></Pagination.Item>);
    items.push(<Pagination.Item key={'left1'} onClick={handlePage(page - 1)}><span aria-hidden="true">‹</span></Pagination.Item>);
    items.push(
        <Pagination.Item activeLabel={false} key={page} onClick={handlePage(1)} active={true}>
        {page}
        </Pagination.Item>
    );
    if( page !== totalPages && totalPages > 0){
        items.push(
            <Pagination.Item activeLabel={false} key={'total1'} onClick={handlePage(totalPages)} active={false}>
            {totalPages}
            </Pagination.Item>
        );
    }
    items.push(<Pagination.Item key={'rigth'} onClick={handlePage(page + 1)}><span aria-hidden="true">›</span></Pagination.Item>);
    items.push(<Pagination.Item key={'rigth1'} onClick={handlePage(totalPages)}><span aria-hidden="true">»</span></Pagination.Item>);

    return(
    <div>
        <h1 className="title">Manage News</h1>
        <hr></hr>
        <div className="contentForm">
        <Link to="/ControlPanel/News/Add">
            <button className="custom-button">Add</button>
        </Link>
        <hr></hr>
        <div className="table-responsive">
            <table className="table-system">
                <thead>
                <tr>
                    <th>Nome</th>
                    <th>Data</th>
                    <th>Tipo</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {notices.Items.map((item, key) => (
                <tr key={key}>
                    <td>{item.name}</td>
                    <td>{dateFormat(item.createdAt, GetDateLocale())}</td>
                    {(() => {
                        if (item.type == 1) {
                            return (
                                <td className="text-success">News</td>
                            )
                            } else if (item.type == 2) {
                            return (
                                <td className="text-info">Events</td>
                            )
                            } else if (item.type == 3) {
                                return (
                                    <td className="text-danger">Update</td>
                                )
                        } 
                    })()}
                    <td>
                        <p>
                            <Link to={{pathname:"/ControlPanel/News/Edit",
                            state: item
                            }}>
                            <span className="color-blue">Edit</span>
                            </Link>
                        </p>
                        <p>
                        <Link to="#" onClick={(e) => handleDelete(e, item.Id)}>
                             <span className="text-danger">Delete</span>
                        </Link>
                        </p>
                        <p>
                            <Link to={{pathname:"/ControlPanel/News/Details",
                            state : {
                                name : item.name,
                                description : item.description
                            }
                            }}>
                            <span className="text-info">Details</span>
                            </Link>
                        </p>
                    </td>
                </tr>
                ))}
                </tbody>
            </table>
        </div>
        </div>
        <br/>
        <Pagination size="sm">{items}</Pagination>
    </div>  
    )
}

export default ControlPanelNews;