import React from "react";
import Route from "./Route";
import APPLICATION_NAME from "./Configuration";

document.title = APPLICATION_NAME;

function App() {
  return (
    <Route />
  );
}


export default App;
