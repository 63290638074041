import React, { useEffect } from "react";
import { NoticeService } from "../notice/service/NoticeService";
import dateFormat, { masks } from "dateformat";
import GetDateLocale from "../../../services/GetDateLocale";
import Slider from "react-slick";
import { Link } from "react-router-dom";


const Home = () => {

    const settings = {
        dots: false,
        infinite: false,
        speed: 2000,
        slidesToShow: 2,
        slidesToScroll: 6,
        autoplay: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 740,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
    };

    const {
        handleNotices,
        handleFixedNotice,
        notices,
        fixedNotice
    } = NoticeService();

    useEffect(() => {
        handleNotices(1, 0);
        handleFixedNotice();
    },[])

    return(
        <div>
            <link rel="stylesheet" type="text/css" href="/assets/css/styleadd.css" />
            <section className="landing">
                <video
                    playsInline="playsinline"
                    autoPlay="autoplay"
                    muted="muted"
                    loop="loop"
                    >
                    <source src="/assets/images/video/Reboot.mp4" />
                </video>
                <div className="container">
                {fixedNotice != null &&
                    <h3 className="toUpper">{fixedNotice.name}</h3>
                }
                <div className="scroll-icon" />
                </div>
                </section>
                <section className="latest-news">
                <div className="container-fluid lg-container">
                    {/* S */}
                    <Slider {...settings}>
                        {notices.Items.map((notice, key) => (
                        <div key={key}  className="news">
                            <Link 
                                to={{pathname:"/Notice/Read",
                                    state : {
                                        notice : notice
                                    }
                                }}
                            >
                                <div className="box_latestnews">
                                    <div className="info_latestnews">
                                        {(() => {
                                            switch (notice.type) {
                                            case 1:   return <span className="cate_g type_cate4">News</span>;
                                            case 2: return <span className="cate_g type_cate4">Events</span>;
                                            case 3:  return <span className="cate_g type_cate4">Updates</span>;
                                            }
                                        })()}
                                        <span className="date_g">{dateFormat(notice.createdAt,"dd/mm/yyyy")}</span>
                                    </div>
                                    <p className="desc_latestnews">
                                        {notice.name}
                                    </p>
                                    </div>
                                    <picture style={{position:"relative", right:"-80px"}}>
                                    <img
                                        src={notice.imageIcon}
                                        width={300}
                                        height={200}
                                        alt=""
                                    />
                                </picture>
                            </Link>
                        </div>
                        ))}
                    </Slider>
                </div>
            </section>
                <section className="intro">
                <div className="video-container">
                    <video
                        playsInline="playsinline"
                        autoPlay="autoplay"
                        muted="muted"
                        loop="loop"
                        >
                        <source src="/assets/images/video/nw.mp4" />
                    </video>
                </div>
                <div className="container xl-container">
                    <h3>Nodewars and Siege</h3>
                    <div className="text-container">
                        <p>
                            Will your Guild be successful? 
                        </p>
                    </div>
                </div>
                </section>
                <section className="wars">
                <div className="container xl-container">
                <div className="row">
                    <div className="slick-wrapper-wars">
                        <div className="slide">
                            <div className="col-md-4 text-box">
                            <h3>SAGE</h3>
                            <div className="text-container">
                                <p>
                                Sage uses a special Kyve material, which he invented, and a Talisman that controls magic.
                                Using Kyve's trait, which freely transforms his form, he can bend time and space to generate great physical strength.
                                </p>
                            </div>
                            </div>
                            <div className="col-md-7 image-box">
                            <img
                                src="/assets/images/classes/game_classes_21_sage-PNG.png"
                                alt="background"
                                className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="slide">
                            <div className="col-md-4 text-box">
                            <h3>Corsair</h3>
                            <div className="text-container">
                                <p>
                                Armed with the flexible sword Serenaka and Mareka, the treasure of the sea with the power of waves, the Corsair is able to
                                use your sword fluidly like running water based on its unique mobility through a rope. Furthermore,
                                being a pirate, she moves faster and stays in the water longer.
                                </p>
                            </div>
                            </div>
                            <div className="col-md-7 image-box">
                            <img
                                src="/assets/images/classes/game_classes_22_corsair-PNG.png"
                                alt="background"
                                className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="keys">
                        <button className="btn-prev">
                        <i className="fa fa-angle-left" />
                        </button>
                        <button className="btn-next">
                        <i className="fa fa-angle-right" />
                        </button>
                    </div>
                    <div className="list_paging" />
                    </div>
                </div>
                </section>
                <section className="characters">
                <div className="container">
                    <div className="slider-for hero-slider-for">
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_00_warrior-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                                <div className="classIcon">
                                    <img src="/assets/images/classes/icon/game_classes_00_btn_warrior.png" alt=""
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                    />
                                </div>
                                <h1>WARRIOR</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_01_ranger-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_01_btn_ranger.png" alt=""
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>RANGER</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_02_sorceress-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_02_btn_sorceress.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>SORCERESS</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_03_giant-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_03_btn_giant.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>BERSERKER</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_04_witch-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_04_btn_witch.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>WITCH</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_05_wizard-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_05_btn_wizard.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>WIZARD</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_06_tamer-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_06_btn_tamer.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>TAMER</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_07_valkyrie-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_07_btn_valkyrie.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>Valkyrie</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_08_ninja-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_08_btn_ninja.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>NINJA</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_09_kunoichi-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_09_btn_kunoichi.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>KUNOICHI</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_10_musa-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_10_btn_musa.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>MUSA</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_11_maehwa-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_11_btn_maehwa.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>MAEHWA</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_12_striker-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_12_btn_striker.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>STRIKER</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_13_darkknight-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_13_btn_darkknight.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>DARK KNIGHT</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_14_mystic-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_14_btn_mystic.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>MYSTIC</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_15_archer-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_15_btn_archer.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>ARCHER</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_16_lahn-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_16_btn_lahn.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>LAHN</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_17_shai-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_17_btn_shai.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>SHAI</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_18_guardian-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_18_btn_guardian.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>GUARDIAN</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_19_hashashin-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_19_btn_hashashin.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>HASHASHIN</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_20_nova-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_20_btn_nova.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>NOVA</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_21_sage-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_21_btn_sage_hover.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>SAGE</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                        {/*sl-slide*/}
                        <div className="sl-slide">
                            <div className="sl-hero">
                            <img src="/assets/images/classes/game_classes_22_corsair-PNG.png" alt="" />
                            </div>
                            <div className="classInfo">
                            <div className="classIcon">
                                <img src="/assets/images/classes/icon/game_classes_22_btn_corsair.png" alt="" 
                                    style={{
                                        width:"50px",
                                        position:"relative",
                                        top:"-8px",
                                        left: "50px"
                                    }}
                                />
                            </div>
                            <h1>CORSAIR</h1>
                            </div>
                        </div>
                        {/*sl-slide*/}
                    </div>
                    <div className="slider-nav hero-slider-nav">
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_00_btn_warrior.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_01_btn_ranger.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_02_btn_sorceress.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_03_btn_giant.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_04_btn_witch.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_05_btn_wizard.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_06_btn_tamer.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_07_btn_valkyrie.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_08_btn_ninja.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_09_btn_kunoichi.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_10_btn_musa.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_11_btn_maehwa.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_12_btn_striker.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_13_btn_darkknight.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_14_btn_mystic.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_15_btn_archer.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_16_btn_lahn.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_17_btn_shai.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_18_btn_guardian.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_19_btn_hashashin.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_20_btn_nova.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_21_btn_sage_hover.png" alt="" />
                        </div>
                        <div className="slick-slide">
                            <img src="/assets/images/classes/icon/game_classes_22_btn_corsair.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home;