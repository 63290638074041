import React, { useState } from "react";
import Api from "../../../../services/Api";
import { getAccount, getUser } from "../../../../services/Auth";
import Swal from "sweetalert2";
import { secondsToTime } from "../../../../services/Functions";
import { b64EncodeUnicode } from "../../../../services/Functions";

export const ControlPanelVoteService = () => {

    const [voteservers, setVoteServers] = useState([])

    const handleVoteServers = async () => {
        await Api.get("/Account/GetVoteServerList")
        .then((response) =>{
            setVoteServers(response.data)
        })
        .catch((err) => {
        });
    }

    const handleVote = (serverId, serverUrl, voteParamFunction, passUserInUrl, voteParam, baseUrl) => {

        let timerInterval
        
        let userName = getAccount().username;

        if(passUserInUrl === 1){
            window.open(serverUrl + userName, "_blank")
        }else if(passUserInUrl === 2){
            var voteParamUrl = serverUrl + userName + "/" + serverId

            const encodedUrl = baseUrl +  encodeURIComponent(b64EncodeUnicode(voteParamUrl))
            window.open(encodedUrl, "_blank")
        }
            
        Swal.fire({
        title: 'Vote for us',
        html: 'Cast your vote within <b></b> minutes, and do not close this window.',
        timer: 300000,
        timerProgressBar: true,
        background: 'rgba(25, 20, 20)',
        didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
            var timePrint = secondsToTime(Swal.getTimerLeft() / 1000);
            b.textContent = timePrint.h + ":" + timePrint.m + ":" + timePrint.s; 
            }, 100)

            setInterval(async () => {
                await Api.get("/Account/" + voteParamFunction + "/" + serverId)
                .then((response) =>{

                    if(response.data.code != ""){
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Successful',
                            text : response.data.message,
                            showConfirmButton: false,
                            timer: 6500,
                            background: 'rgba(43, 43, 46, 0.8)'
                        })
    
                        clearInterval(timerInterval)
                    }

                    handleVoteServers()

                })
                .catch((err) => {
        
                });
            }, 60000);
        },
        willClose: () => {
            clearInterval(timerInterval)
        }
        }).then((result) => {
        /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
            }
        })

    }


    const CheckVote = (seconds, serverId, serverUrl, voteParamFunction, passUserInUrl, voteParam, baseUrl) => {

        let user = getUser();

        if(user == null){
            let timerInterval
        
            Swal.fire({
            title: 'Error',
            html: 'You never logged in, try again later.',
            timer: seconds * 1000,
            timerProgressBar: true,
            background: 'rgba(25, 20, 20)',
            didOpen: () => {
                Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {
                var timePrint = secondsToTime(Swal.getTimerLeft() / 1000);
                    b.textContent = timePrint.h + ":" + timePrint.m + ":" + timePrint.s; 
                }, 100)
    
            },
            willClose: () => {
                clearInterval(timerInterval)
            }
            }).then((result) => {
                
            })

            return;
        }

        if(seconds === 0){
            handleVote(serverId, serverUrl, voteParamFunction, passUserInUrl, voteParam, baseUrl)
            return
        }

        let timerInterval
        
        Swal.fire({
        title: 'Vote for us',
        html: 'Wait for the <b></b> total to vote again for this server.',
        timer: seconds * 1000,
        timerProgressBar: true,
        background: 'rgba(25, 20, 20)',
        didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
            var timePrint = secondsToTime(Swal.getTimerLeft() / 1000);
                b.textContent = timePrint.h + ":" + timePrint.m + ":" + timePrint.s; 
            }, 100)

        },
        willClose: () => {
            clearInterval(timerInterval)
        }
        }).then((result) => {
            
        })
    }

    return{
        handleVoteServers,
        handleVote,
        CheckVote,
        voteservers
    }
}