import React, { useState } from "react";
import Api from "../../../../services/Api";
import { account } from "../../../../services/Auth";

const ControlPanelGlobalData = () => {

    const [accountData, setAccountData] = useState([]);

    const getAccountData = async () => {
        await Api.get("/Auth/GetAccountData")
        .then((response) => {
            // setAccountData(response.data)
            account(response.data)
        })
        .catch((err) => {
    
        })
    }

    return {
        getAccountData,
        accountData
    }
}

export default ControlPanelGlobalData;