import React from "react";
import ControlPanelRecoverGiftCodeService from "./service/ControlPanelRecoverGiftCodeService";
import ValidateForm from "../../../services/ValidateForm";

const ControlPanelRecoverGiftCode = ({submitForm}) => {

    const {
        errors,
        values,
        handleChange,
        handleSubmitRecoverGiftCode
    } = ControlPanelRecoverGiftCodeService(
        submitForm,
        ValidateForm
    );

    return(
        <div>
            <h1 className="title">Enter Coupon Code</h1>
            <div className="contentForm">
                <form onSubmit={handleSubmitRecoverGiftCode}>
                    <div className={values.GiftCode === "" ? "formGroup" : "formGroup on"}>
                        <label>Coupon Code</label>
                        <input type="text" name="GiftCode"
                            value={values.GiftCode}
                            onChange={handleChange}
                        />
                    </div>
                    {errors.GiftCode && 
                    <div className="form-msg form-error">
                        <i className="sp-form noti"></i> {errors.GiftCode}
                    </div>
                    }
                    <button className="custom-button">Claim</button>
                </form>
            </div>
        </div>
    )
}

export default ControlPanelRecoverGiftCode;